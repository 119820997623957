<div class="jumbotron text-center header-padding-margin">
    <img [src]="headerImg" alt="Chevron">
  <p style="font-family:Arial,sans-serif; color:#0B2D71; font-size: 36px;"><b>Road Safety Analytics</b></p>
  </div>
    
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <img class="card-img-top img-center" [src]="tile4Img" alt="RSA Information">
          <div class="card-body">
            <h6 class="card-title" style="text-align:center">Information and Training</h6>
            <button (click)="openInformationTraining()" type="button" class="btn btn-primary button-links" target="_blank">Select</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img class="card-img-top img-center tile-width" [src]="tile2Img" alt="RSA Dashboard">
          <div class="card-body">
            <h6 class="card-title" style="text-align:center">Road Safety Analytics Dashboard</h6>
            <button (click)="openDashboard()" type="button" class="btn btn-primary button-links" target="_blank">Select</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img class="card-img-top img-center" [src]="tile1Img" alt="SRPT">
          <div class="card-body">
            <h6 class="card-title" style="text-align:center">Safe Route Planning Tool</h6>
            <button (click)="openSrpt()" type="button" class="btn btn-primary button-links" target="_blank">Select</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img class="card-img-top img-center tile-width" [src]="tile3Img" alt="Journey Mgmt">
          <div class="card-body ">
            <h6 class="card-title" style="text-align:center">Journey Management</h6>
            <button (click)="openJourneymgmt()" type="button" class="btn btn-primary button-links" target="_blank">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 
